import logo from "./assets/logo.png";
import video from "./assets/GIF.mp4";
import btn from "./assets/btn.svg";
import contract from "./assets/contract.svg";
import dt from "./assets/dextools.svg";
import uni from "./assets/uniswap.svg";
import dc from "./assets/dc.svg";
import "./App.css";
import aud from "./assets/PigGangGangNpcYesYesYesIceCreamSoGood.mp3";
import { useState } from "react";

function App() {
  const [isOpen, setIsOpen] = useState(true);

  const audio = new Audio(aud);

  return (
    <div className="App">
      <video playsInline loop muted autoPlay>
        <source src={video} type="video/mp4" />
      </video>
      <div class="logo">
        <img src={logo} alt="logo" />
      </div>
      <div class="btn-group">
        <div class="btn-group-row">
          <a
            class="btn-wrapper"
            href="#"
            target="_blank"
            rel="noopener noreferrer">
            <div class="btn">
              <span>Contract</span>
              <div class="btn-icon">
                <img src={contract} alt="" />
              </div>
            </div>
            <div class="btn-wrapper-icon">
              <img src={btn} alt="" />
            </div>
          </a>
          <a
            class="btn-wrapper"
            href="#"
            target="_blank"
            rel="noopener noreferrer">
            <div class="btn">
              <span>Uniswap</span>
              <div class="btn-icon">
                <img src={uni} alt="" />
              </div>
            </div>
            <div class="btn-wrapper-icon">
              <img src={btn} alt="" />
            </div>
          </a>
        </div>
        <div class="btn-group-row">
          <a
            class="btn-wrapper"
            href="#"
            target="_blank"
            rel="noopener noreferrer">
            <div class="btn">
              <span>Dextools</span>
              <div class="btn-icon">
                <img src={dt} alt="" />
              </div>
            </div>
            <div class="btn-wrapper-icon">
              <img src={btn} alt="" />
            </div>
          </a>
          <a
            class="btn-wrapper"
            href="#"
            target="_blank"
            rel="noopener noreferrer">
            <div class="btn">
              <span>Dexscreener</span>
              <div class="btn-icon btn-icon-sp">
                <img src={dc} alt="" />
              </div>
            </div>
            <div class="btn-wrapper-icon">
              <img src={btn} alt="" />
            </div>
          </a>
        </div>
      </div>

      <div class="btn-social">
        <a
          href="https://t.me/icecreamsogooderc"
          target="_blank"
          rel="noopener noreferrer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            viewBox="0 0 73 73"
            fill="none">
            <path
              d="M36.2786 72.5413C56.3148 72.5413 72.5573 56.3023 72.5573 36.2706C72.5573 16.2389 56.3148 0 36.2786 0C16.2425 0 0 16.2389 0 36.2706C0 56.3023 16.2425 72.5413 36.2786 72.5413Z"
              fill="#6FC087"></path>
            <path
              d="M53.961 21.5475C53.7998 21.1176 53.6386 21.0101 53.4236 20.9564C52.8324 20.7415 51.9187 21.0639 51.9187 21.0639C51.9187 21.0639 17.9511 33.2078 16.0163 34.5512C15.5863 34.8198 15.4788 35.0348 15.3713 35.196C15.0488 36.1632 16.07 36.593 16.07 36.593L24.8306 39.441C24.8306 39.441 25.1531 39.4947 25.2606 39.441C27.2492 38.2051 45.2542 26.8134 46.2753 26.4373C46.4366 26.3836 46.5441 26.4373 46.5441 26.5447C46.1141 27.9956 30.474 41.9128 30.474 41.9128C30.474 41.9128 30.4202 41.9665 30.3665 42.0739L29.5603 50.7252C29.5603 50.7252 29.2378 53.3581 31.8714 50.7252C33.7525 48.8445 35.5799 47.2862 36.4398 46.5339C39.4496 48.6295 42.6743 50.8864 44.0717 52.1223C44.7704 52.7133 45.3617 52.8208 45.8454 52.8208C47.189 52.7671 47.5652 51.3162 47.5652 51.3162C47.5652 51.3162 53.7461 26.4373 53.961 23.1058C53.961 22.7834 54.0148 22.5684 54.0148 22.3535C54.0148 21.9773 54.0148 21.7087 53.961 21.5475Z"
              fill="white"></path>
          </svg>
        </a>
        <a
          href="https://twitter.com/icecreamsogoodd"
          target="_blank"
          rel="noopener noreferrer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            viewBox="0 0 73 73"
            fill="none">
            <path
              d="M36.2786 72.5413C56.3148 72.5413 72.5573 56.3023 72.5573 36.2706C72.5573 16.2389 56.3148 0 36.2786 0C16.2425 0 0 16.2389 0 36.2706C0 56.3023 16.2425 72.5413 36.2786 72.5413Z"
              fill="#6FC087"></path>
            <path
              d="M28.9692 57.1732C46.7054 57.1732 56.3797 42.5038 56.3797 29.7688C56.3797 29.3389 56.3797 28.9627 56.3259 28.5329C58.207 27.1895 59.8194 25.47 61.1093 23.5356C59.3895 24.2879 57.5083 24.8252 55.5735 25.0401C57.5621 23.858 59.067 21.9773 59.8194 19.7205C57.9383 20.8489 55.896 21.6012 53.6924 22.0848C51.9187 20.2041 49.4464 19.0219 46.6516 19.0219C41.3307 19.0219 37.0311 23.3207 37.0311 28.6403C37.0311 29.3926 37.1386 30.1449 37.2998 30.8434C29.2916 30.4673 22.1971 26.5984 17.4675 20.7951C16.6613 22.1922 16.1775 23.858 16.1775 25.6312C16.1775 28.9627 17.8974 31.9181 20.4772 33.6376C18.9186 33.5839 17.4137 33.154 16.1238 32.4555C16.1238 32.5092 16.1238 32.5092 16.1238 32.5629C16.1238 37.2378 19.4561 41.1067 23.8633 42.0202C23.0571 42.2351 22.1971 42.3426 21.3372 42.3426C20.6922 42.3426 20.101 42.2888 19.5098 42.1814C20.746 45.9965 24.2932 48.7907 28.4854 48.8444C25.2069 51.4237 21.0147 52.982 16.5 52.982C15.7476 52.982 14.9414 52.9282 14.189 52.8745C18.4349 55.5612 23.487 57.1732 28.9692 57.1732Z"
              fill="white"></path>
          </svg>
        </a>
      </div>

      <div class={`modal ${isOpen ? "" : "hidden"} `}>
        <h4>Who is the author of the theory of relativity?</h4>
        <div class="modal-btn-group">
          <span
            onClick={() => {
              audio.play();
              audio.loop = true;
              setIsOpen(false);
            }}>
            Albert Einstein
          </span>
          <span class="">Elon Musk</span>
        </div>
      </div>
    </div>
  );
}

export default App;
